/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: script.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:44:10
 Modificado por: pedro.rosa
*/
import depColecCateg from "./components/depColecCateg/V36SF09003B.vue";
import grupos from "./components/grupo/V36SF09003C.vue";
import moment from "moment";
import i18n from "@/i18n";
export default {
  components: { grupos, depColecCateg },
  data: () => {
    return {
      ativaGrafico: false,
      ativaAvisoGrafico: false,
      checksChart: {
        options: {
          tooltip: {
            enabled: true,
            theme: true,
          },
          title: {
            text: "",
            align: "center",
            margin: 0,
            style: {
              fontSize: "25px",
              fontWeight: "bolder",
              fontFamily: "calibri",
            },
          },
          colors: [
            "#FF6060",
            "#FF7A60",
            "#FF9760",
            "#FFA860",
            "#FFC560",
            "#FFE760",
            "#FFFC60",
            "#EAFF60",
            "#DEFF60",
            "#BEFF60",
            "#9AFF60",
            "#71FF60",
            "#60FF83",
            "#60FFB3",
            "#60FFCE",
            "#60FFEF",
            "#60EDFF",
            "#60C6FF",
            "#60B3FF",
            "#6094FF",
            "#606BFF",
            "#7660FF",
            "#9560FF",
            "#B260FF",
            "#D460FF",
            "#EA60FF",
            "#FF60F5",
            "#FF60BE",
            "#FF60A1",
            "#FF608B",
            "#FF6078",
          ],
          legend: {
            fontSize: "16px",
            fontFamily: "Calibri",
            fontWeight: 400,
            itemMargin: {
              vertical: 10,
            },
            formatter: function(seriesName, opts) {
              return (
                "<span class= 'legend-info'>" +
                seriesName +
                "</span>" +
                ":  " +
                "<span>" +
                "<strong>" +
                opts.w.globals.series[opts.seriesIndex] +
                "</strong>" +
                "</span>"
              );
            },
          },
          dataLabels: {
            enabled: true,
          },
          chart: {
            background: "rgba(0,0,0,0)",

            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 1200,
              animateGradually: {
                enabled: true,
                delay: 300,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 2000,
              },
            },
          },
          theme: {
            mode: "",
          },
          labels: [],
        },
        series: [],
      },
      itemGrafico: [
        {
          nome: "Grupos",
          bind: "ce113_ds_grp",
          sp: "SP_CE360901001",
        },
        {
          nome: "Departamento",
          bind: "ce175_ds_depto",
          sp: "SP_CE360901027",
        },
        {
          nome: "Seção",
          bind: "ce176_ds_secao",
          sp: "SP_CE360901028",
        },
        {
          nome: "Categoria",
          bind: "ce177_ds_categ",
          sp: "SP_CE360901029",
        },
        {
          nome: "Coleção",
          bind: "ce178_ds_colec",
          sp: "SP_CE360901030",
        },

        {
          nome: "Marca",
          bind: "ce179_ds_marca",
          sp: "SP_CE360901031",
        },
        {
          nome: "Cor",
          bind: "ce117_ds_cor",
          sp: "SP_CE360901032",
        },
        {
          nome: "Tamanho",
          bind: "ce114_nm_tam",
          sp: "SP_CE360901033",
        },
        {
          nome: "Fabricantes",
          bind: "ce115_nm_razao",
          sp: "SP_CE360901035",
        },
      ],
      keyGraph: 0,
      escolhaGrafico: "",
      escolha: "",
      item: [
        "Grupos",
        "Departamento",
        "Seção",
        "Coleção",
        "Categoria",
        "Marca",
        "Fabricantes",
        "Cor",
        "Tamanho",
      ],
      count: 0,
      cards: [
        {
          nome: i18n.tc("TelaOrgProdutos.grupos"),
          id: "Grupos",
          total: 0,
          icon: "toc", //account_tree
        },
        {
          nome: i18n.tc("TelaOrgProdutos.departamento"),
          id: "Departamento",
          total: 0,
          icon: "toc", //store
        },
        {
          nome: i18n.tc("TelaOrgProdutos.secao"),
          id: "Seção",
          total: 0,
          icon: "toc", //article
        },
        {
          nome: i18n.tc("TelaOrgProdutos.colecao"),
          id: "Coleção",
          total: 0,
          icon: "toc", //checkroom
        },
        {
          nome: i18n.tc("TelaOrgProdutos.categoria"),
          id: "Categoria",
          total: 0,
          icon: "toc", //category
        },

        {
          nome: i18n.tc("TelaOrgProdutos.marca"),
          id: "Marca",
          total: 0,
          icon: "toc", //local_offer
        },
        {
          nome: i18n.tc("TelaOrgProdutos.fabricantes"),
          id: "Fabricantes",
          total: 0,
          icon: "toc",
        },
        {
          nome: i18n.tc("TelaOrgProdutos.cor"),
          id: "Cor",
          total: 0,
          icon: "toc", //palette
        },
        {
          nome: i18n.tc("TelaOrgProdutos.tamanho"),
          id: "Tamanho",
          total: 0,
          icon: "toc", //text_fields
        },
      ],
    };
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    isDark() {
      this.isDark
        ? (this.checksChart.options.theme.mode = "dark")
        : (this.checksChart.options.theme.mode = "");
      this.keyGraph++;
    },
  },
  mounted() {
    this.getTotals();
    this.removeGrid();
  },
  methods: {
    async mostraEscolha() {
      if (!this.item.find((f) => f == this.escolha)) {
        this.notification().warn({ mensagem: "Escolha uma das opções"});
        this.escolha = "";
      }
    },
    async getTotals() {
      try {
        this.startLoader();
        let totalDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901024",
          params: {},
        });
        this.mapeiaCards(totalDB);
      } catch (err) {
        this.notification().error({erro: "Erro ao trazer os totais!"});
      } finally {
        this.stopLoader();
        this.continuaBusca();
      }
    },
    async continuaBusca() {
      setInterval(async () => {
        let totalDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901024",
          params: {},
        });
        this.mapeiaCards(totalDB);
      }, 60000);
    },
    mapeiaCards(totalDB) {
      totalDB.database.forEach((f) => {
        let index = this.cards.findIndex((el) => el.id == f.nome);
        this.cards[index].total = f.total;
        this.cards[index].dt_atusis = moment(f.dt_atusis, "x").format(
          "DD/MM/YYYY HH:mm",
        );
      });
    },
    escolher(escolha) {
      if (escolha == "Grupos") {
        this.escolha = escolha;
        this.$route.query.grupo = "grupo";
      } else {
        this.escolha = escolha;
        this.$route.query.grupo = null;
      }
      this.count++;
    },
    removeGrid(nome) {
      if (this.escolha != "") {
        this.escolha == nome ? (this.escolha = "") : null;
      }
    },
    removeGrafico(item) {
      if (this.escolhaGrafico != "" && item == this.escolhaGrafico) {
        this.escolhaGrafico == item ? (this.ativaGrafico = false) : null;
        this.checksChart.options.labels = [];
        this.checksChart.series = [];
      }
    },
    ativadorGrafico() {
      this.ativaGrafico = !this.ativaGrafico;
      if (this.ativaGrafico == false) {
        this.removeGrafico(this.escolhaGrafico);
      }
      this.keyGraph++;
    },
    tituloGrafico(item) {
      item
        ? (this.checksChart.options.title.text = `${item} X Produtos`)
        : (this.checksChart.options.title.text = "");
    },
    async populaGrafico(escolha) {
      this.startLoader();
      let nomeGrupos = [];
      let produtosFiltrados = [];
      this.escolhaGrafico = escolha;
      this.ativadorGrafico();
      let bind = this.itemGrafico.find((item) => item.nome === escolha);
      if (this.checksChart.series.length == 0 && this.ativaGrafico == true) {
        let grupo = await this.request({
          metodo: "get",
          sp: bind.sp,
          params: { _rSITUAC: 1 },
        });
        let produtos = await this.chamaMulti("SP_CE360901000", {
          quantidade: "Todos",
          filtros: [{}],
        });
        grupo = grupo.database;

        nomeGrupos = grupo.map((f) => {
          return {
            text: f[bind.bind],
            value: 0,
          };
        });
        produtos = produtos.database;
        produtos.forEach((produto) => {
          nomeGrupos.forEach((grupo) => {
            if (produto[bind.bind] == grupo.text) {
              grupo.value++;
            }
          });
        });
        // console.log(nomeGrupos);
        nomeGrupos.forEach((item) => {
          if (item.value != 0) {
            item.text = item.text.substring(0, 15) + "...";
            produtosFiltrados.push(item);
          }
        });
        // console.log(produtosFiltrados);
        if (produtosFiltrados.length > 0) {
          produtosFiltrados.forEach((prods) => {
            this.checksChart.options.labels.push(prods.text);
            this.checksChart.series.push(prods.value);
          });
        } else {
          this.ativaAvisoGrafico = true;
        }
      }
      this.stopLoader();
    },
  },
};
